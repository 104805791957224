import avatar1 from "../images/1.png";
import avatar2 from "../images/2.png";
import avatar3 from "../images/3.png";
import avatar4 from "../images/4.png";
import avatar5 from "../images/5.png";
import avatar6 from "../images/6.png";
import avatar7 from "../images/7.png";
import avatar8 from "../images/8.png";
import avatar9 from "../images/9.png";
import avatar10 from "../images/10.png";

const images = {
    avatar1,
    avatar2,
    avatar4,
    avatar3,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
}

export default images